/* Contact Us Section */
.contact-us-section {
  background-color: #1e1e1e;
  color: white;
  padding: 80px 20px;
  margin-top: 80px;
}

.contact-us-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
}

.contact-us-section h2 {
  width: 100%;
  font-size: 5em;
  margin-bottom: 20px;
  color: white;
  text-align: left;
}

.contact-us-details {
  flex: 1;
  min-width: 300px;
}

.contact-us-details p {
  margin: 15px 0;
  font-size: 2.6em;
  display: flex;
  align-items: center;
  line-height: 1.6;
}

.contact-us-details i {
  color: #27ae60;
  margin-right: 10px;
  font-size: 1.5em;
}

/* Form Styling */
.contact-us-form {
  flex: 1;
  min-width: 400px;
  background-color: white;
  color: black;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.contact-us-form h3 {
  margin-bottom: 20px;
  font-size: 2.2em;
}

.contact-us-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-us-form input,
.contact-us-form textarea {
  font-size: 1.2em;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f9f9f9;
  color: #333;
}

.contact-us-form input:focus,
.contact-us-form textarea:focus {
  border-color: #27ae60;
  outline: none;
}

.contact-us-form input::placeholder,
.contact-us-form textarea::placeholder {
  color: #888;
}

.contact-us-form button {
  font-size: 1.3em;
  padding: 12px;
  background: #27ae60;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.contact-us-form button:hover {
  background: #1e8e50;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .contact-us-container {
    flex-direction: column;
    gap: 20px;
  }

  .contact-us-section h2 {
    font-size: 3em;
    text-align: center;
  }

  .contact-us-form h3 {
    font-size: 2em;
  }

  .contact-us-details p {
    font-size: 2.4em;
  }
}

@media (max-width: 768px) {
  .contact-us-section h2 {
    font-size: 2.5em;
    text-align: center;
  }

  .contact-us-form h3 {
    font-size: 1.8em;
  }

  .contact-us-form input,
  .contact-us-form textarea {
    font-size: 1.1em;
  }

  .contact-us-form button {
    font-size: 1.2em;
  }

  .contact-us-details p {
    font-size: 2.2em;
  }
}

@media (max-width: 480px) {
  .contact-us-section h2 {
    font-size: 2em;
    text-align: center;
  }

  .contact-us-form h3 {
    font-size: 1.6em;
  }

  .contact-us-details p {
    font-size: 2em;
  }

  .contact-us-form input,
  .contact-us-form textarea {
    font-size: 1em;
  }

  .contact-us-form button {
    font-size: 1.1em;
  }
}
