.about-us-hero {
  background-color: #222;
  color: white;
  padding: 100px 20px;
}

.about-us-hero .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.about-us-hero .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.about-us-hero .title-section {
  text-align: left;
  margin-bottom: 50px;
}

.about-us-hero .title-section h1 {
  font-size: 4em;
  margin: 0;
  font-weight: bold;
}

.about-us-hero .text-section {
  margin-bottom: 50px;
  width: 100%;
}

.about-us-hero .headline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
}

.about-us-hero .headline h2 {
  font-size: 3.5em;
  margin-bottom: 20px;
  color: #21d07a;
}

.about-us-hero .headline p {
  font-size: 1.8em;
  line-height: 1.6;
  max-width: 800px;
  color: #aaa;
}

.about-us-hero .stats {
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
}

.about-us-hero .stat {
  flex: 1;
  text-align: center;
  margin: 0 15px;
}

.about-us-hero .stat h3 {
  font-size: 2.6em;
  color: #21d07a;
  margin-bottom: 10px;
}

.about-us-hero .stat p {
  font-size: 1.6em;
  color: #ddd;
}

/* Responsive Design for Tablets */
@media (max-width: 1024px) {
  .about-us-hero .title-section h1 {
    font-size: 3.5em;
  }

  .about-us-hero .headline h2 {
    font-size: 2.4em;
  }

  .about-us-hero .headline p {
    font-size: 1.6em;
    max-width: 90%;
  }

  .about-us-hero .stat h3 {
    font-size: 2.4em;
  }

  .about-us-hero .stat p {
    font-size: 1.4em;
  }

  .about-us-hero .stats {
    gap: 10px;
  }
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .about-us-hero {
    padding: 60px 15px;
  }

  .about-us-hero .title-section h1 {
    font-size: 3em;
  }

  .about-us-hero .headline h2 {
    font-size: 2.2em;
  }

  .about-us-hero .headline p {
    font-size: 1.4em;
  }

  .about-us-hero .stat h3 {
    font-size: 2.2em;
  }

  .about-us-hero .stat p {
    font-size: 1.2em;
  }

  .about-us-hero .stats {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .about-us-hero .stat {
    width: 100%;
    margin: 10px 0;
  }
}

/* Responsive Design for Very Small Devices */
@media (max-width: 480px) {
  .about-us-hero {
    padding: 40px 15px;
  }

  .about-us-hero .title-section h1 {
    font-size: 2.5em;
  }

  .about-us-hero .headline h2 {
    font-size: 2em;
  }

  .about-us-hero .headline p {
    font-size: 1.2em;
  }

  .about-us-hero .stat h3 {
    font-size: 1.8em;
  }

  .about-us-hero .stat p {
    font-size: 1em;
  }

  .about-us-hero .stats {
    flex-direction: column;
    gap: 10px;
  }
}
