.affiliate-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding: 30px 0;
  margin-bottom: 30px;
  position: relative;
}

.trusted-by-text {
  color: #fff;
  font-size: 16px;
  margin-bottom: 15px;
  text-align: center;
}

.elementor-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.elementor-row {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.elementor-column {
  flex: 0 0 auto;
  max-width: 12%;
  text-align: center;
  margin: 0 50px;
}

.elementor-image img {
  width: 60px;
  height: 60px;
  display: block;
  margin: 0 auto;
}

.arrow {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.arrow-left {
  left: 10px;
}

.arrow-right {
  right: 10px;
}

/* Tablet view (max-width: 1024px) */
@media (max-width: 1024px) {
  .elementor-container {
    max-width: 90%;
  }

  .elementor-column {
    max-width: 20%;
    margin: 0 30px;
  }

  .elementor-image img {
    width: 55px;
    height: 55px;
  }

  .arrow {
    font-size: 18px;
  }
}

/* Mobile view (max-width: 768px) */
@media (max-width: 768px) {
  .elementor-container {
    max-width: 95%;
  }

  .elementor-column {
    max-width: 30%;
    margin: 0 20px;
  }

  .elementor-image img {
    width: 45px;
    height: 45px;
  }

  .arrow {
    font-size: 16px;
  }
}

/* Very small devices (max-width: 480px) */
@media (max-width: 480px) {
  .trusted-by-text {
    font-size: 14px;
  }

  .elementor-container {
    max-width: 100%;
  }

  .elementor-column {
    max-width: 40%;
    margin: 0 15px;
  }

  .elementor-image img {
    width: 40px;
    height: 40px;
  }

  .arrow {
    font-size: 14px;
  }
}
