.whats-our-secret {
  display: flex;
  align-items: center;
  background-color: #1e1e1e;
  color: white;
  padding: 60px 20px;
}

.whats-our-secret .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

.whats-our-secret .image-content {
  display: grid;
  grid-template-areas:
    ". person2"
    "person1 person2"
    "person1 person3"
    ". person3";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 10px;
  max-width: 40%;
}

#person1 {
  grid-area: person1;
}

#person2 {
  grid-area: person2;
}

#person3 {
  grid-area: person3;
}

.whats-our-secret .person-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.whats-our-secret .text-content {
  max-width: 50%;
  padding-left: 20px;
}

.whats-our-secret .text-content h4 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #21d07a;
  text-transform: uppercase;
}

.whats-our-secret .text-content h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.whats-our-secret .text-content p {
  font-size: 18px;
  margin-bottom: 30px;
}

.whats-our-secret .btn {
  display: inline-block;
  padding: 10px 30px;
  border: 2px solid #21d07a;
  color: #21d07a;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  background: none;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.3s ease;
}

.whats-our-secret .btn:hover {
  background-color: #21d07a;
  color: white;
}

/* Tablet Screens */
@media (max-width: 1024px) {
  .whats-our-secret .container {
    flex-direction: column;
    text-align: center;
  }

  .whats-our-secret .text-content {
    order: -1; /* Text content appears before images */
    max-width: 100%;
    padding-left: 0;
  }

  .whats-our-secret .image-content {
    max-width: 70%;
    grid-template-areas:
      ". person2"
      "person1 person2"
      "person1 person3"
      ". person3";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    gap: 8px;
  }

  .whats-our-secret .person-image {
    width: 100%;
    height: auto;
  }

  .whats-our-secret .text-content h2 {
    font-size: 32px;
  }

  .whats-our-secret .text-content p {
    font-size: 16px;
  }
}

/* Mobile Screens */
@media (max-width: 768px) {
  .whats-our-secret .container {
    flex-direction: column;
    text-align: center;
  }

  .whats-our-secret .text-content {
    order: -1; /* Text content appears before images */
    max-width: 100%;
    padding-left: 0;
  }

  .whats-our-secret .image-content {
    max-width: 90%;
    grid-template-areas:
      ". person2"
      "person1 person2"
      "person1 person3"
      ". person3";
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }

  .whats-our-secret .person-image {
    width: 100%;
    height: auto;
  }

  .whats-our-secret .text-content h2 {
    font-size: 28px;
  }

  .whats-our-secret .text-content p {
    font-size: 16px;
  }

  .whats-our-secret .btn {
    padding: 8px 20px;
    font-size: 14px;
  }
}

/* Very Small Devices */
@media (max-width: 480px) {
  .whats-our-secret .container {
    flex-direction: column;
  }

  .whats-our-secret .text-content {
    order: -1; /* Text content appears before images */
    max-width: 100%;
    padding-left: 0;
  }

  .whats-our-secret .image-content {
    max-width: 100%;
    grid-template-areas:
      ". person2"
      "person1 person2"
      "person1 person3"
      ". person3";
    grid-template-columns: repeat(2, 1fr);
    gap: 3px;
  }

  .whats-our-secret .person-image {
    width: 100%;
    height: auto;
  }

  .whats-our-secret .text-content h2 {
    font-size: 24px;
  }

  .whats-our-secret .text-content p {
    font-size: 14px;
  }

  .whats-our-secret .btn {
    padding: 6px 15px;
    font-size: 12px;
  }
}
