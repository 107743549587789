.our-approach {
  display: flex;
  align-items: center;
  background-color: #1e1e1e;
  color: white;
  padding: 60px 20px;
  overflow: hidden;
}

.our-approach .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

.our-approach .text-content {
  flex: 1;
  max-width: 50%;
}

.our-approach .text-content h4 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #21d07a;
  text-transform: uppercase;
}

.our-approach .text-content h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.our-approach .text-content p {
  font-size: 18px;
  margin-bottom: 30px;
}

.our-approach .btn {
  display: inline-block;
  padding: 10px 30px;
  border: 2px solid #21d07a;
  color: #21d07a;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  background: none;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.3s ease;
}

.our-approach .btn:hover {
  background-color: #21d07a;
  color: white;
}

.our-approach .image-content {
  flex: 1;
  max-width: 50%;
}

.our-approach .image-content img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

/* Responsive Design for Tablets */
@media (max-width: 1024px) {
  .our-approach {
    flex-direction: column;
    padding: 40px 20px;
  }

  .our-approach .container {
    flex-direction: column;
    gap: 30px;
  }

  .our-approach .text-content {
    max-width: 100%;
    text-align: center;
  }

  .our-approach .image-content {
    max-width: 100%;
  }

  .our-approach .text-content h2 {
    font-size: 32px;
  }

  .our-approach .text-content p {
    font-size: 16px;
  }
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .our-approach {
    padding: 30px 15px;
  }

  .our-approach .text-content h2 {
    font-size: 28px;
  }

  .our-approach .text-content p {
    font-size: 16px;
  }

  .our-approach .btn {
    padding: 8px 20px;
    font-size: 14px;
  }
}

/* Very small screens (max-width: 480px) */
@media (max-width: 480px) {
  .our-approach {
    padding: 20px 10px;
  }

  .our-approach .text-content h2 {
    font-size: 24px;
  }

  .our-approach .text-content p {
    font-size: 14px;
  }

  .our-approach .btn {
    padding: 6px 15px;
    font-size: 12px;
  }
}
