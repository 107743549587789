body,
html {
  height: 100%;
  margin: 0;
  font-size: 67%;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #000;
}

.background-overlay {
  background-color: transparent;
  background-image: radial-gradient(
    at top left,
    rgba(69, 245, 161, 0.37) 0%,
    rgba(0, 0, 0, 0) 25%
  );
  opacity: 1;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.app-wrapper {
  overflow-x: hidden;
}

.app-container {
  position: relative;
  z-index: 1;
}

main {
  min-height: 80vh;
}

footer {
  background-color: #000;
  color: #fff;
  padding: 20px;
  text-align: center;
  position: relative;
  width: 100%;
}
