.footer {
  background-color: #000;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.footer-copyright {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.footer-social-icons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.footer-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: #333;
  border-radius: 4px;
  color: #fff;
  transition: background-color 0.3s, color 0.3s;
}

.footer-icon i {
  font-size: 18px;
}

.footer-icon:hover {
  background-color: #fff;
  color: #000;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
  }
  .footer-social-icons {
    margin-top: 10px;
  }
}
