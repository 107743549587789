.career-image-banner {
  background-color: #222;
  padding: 40px 0;
}

.career-image-banner .container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.career-image-banner .image-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.career-image-banner .image-wrapper img {
  width: 280px;
  height: 420px;
  border-radius: 10px;
  object-fit: cover;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.career-image-banner .img1 {
  margin-top: 0;
}

.career-image-banner .img2 {
  margin-top: 30px;
}

.career-image-banner .img3 {
  margin-top: 0;
}

.career-image-banner .img4 {
  margin-top: 30px;
}

/* Responsive Design for Tablets (max-width: 1024px) */
@media (max-width: 1024px) {
  .career-image-banner .image-wrapper {
    gap: 20px;
  }

  .career-image-banner .image-wrapper img {
    width: 220px;
    height: 330px;
  }
}

/* Responsive Design for Mobile (max-width: 768px) */
@media (max-width: 768px) {
  .career-image-banner .container {
    flex-direction: column;
  }

  .career-image-banner .image-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .career-image-banner .image-wrapper img {
    width: 200px;
    height: 300px;
  }

  .career-image-banner .img1,
  .career-image-banner .img3 {
    margin-top: 10px;
  }

  .career-image-banner .img2,
  .career-image-banner .img4 {
    margin-top: 20px;
  }
}

/* Very small screens (max-width: 480px) */
@media (max-width: 480px) {
  .career-image-banner .image-wrapper {
    gap: 15px;
  }

  .career-image-banner .image-wrapper img {
    width: 160px;
    height: 240px;
  }

  .career-image-banner .img1,
  .career-image-banner .img3 {
    margin-top: 10px;
  }

  .career-image-banner .img2,
  .career-image-banner .img4 {
    margin-top: 15px;
  }
}
