/* Header Base Styling (for default screens) */
header.custom-header {
  background: rgba(0, 0, 0, 0);
  color: #fff;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: background 0.3s ease;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

header.custom-header.custom-header-scrolled {
  background: rgba(37, 43, 45, 255) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Navbar brand/logo */
.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navbar-brand img {
  height: 180px;
  width: auto;
}

/* Navigation menu */
.navbar-nav {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 30px;
}

.navbar-nav .nav-item {
  padding: 0 -10px;
}

.navbar-nav .nav-link {
  color: #fff !important;
  padding: 5px 15px;
  font-size: 16px;
  transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: #1c544c !important;
}

/* CTA Button Styling */
.cta-button {
  background-color: #1c544c;
  color: #fff !important;
  padding: 8px 20px;
  border-radius: 25px;
  font-size: 14px;
  white-space: nowrap;
  margin-left: 20px;
  border: 2px solid transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.cta-button:hover {
  background-color: #fff;
  color: #1c544c !important;
  border: 2px solid #1c544c;
}

.container {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

/* Responsive Design for Intermediate Screens (max-width: 1024px) */
@media (max-width: 1024px) {
  header.custom-header {
    padding: 10px 20px;
    height: auto;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0);
    width: 100vw;
    left: 0;
    right: 0;
    box-sizing: border-box;
    transition: background 0.3s ease, width 0.3s ease, height 0.3s ease;
    z-index: 1000;
  }

  header.custom-header.custom-header-scrolled {
    background: rgba(37, 43, 45, 1);
    width: 100vw;
    height: 90px;
    box-sizing: border-box;
    left: 0;
    right: 0;
    z-index: 9999;
    transition: background 0.3s ease, width 0.3s ease, height 0.3s ease;
  }

  /* Adjust navbar and logo for intermediate screens */
  .navbar-brand {
    position: absolute;
    left: 10px;
    top: 30px;
    transform: none;
    z-index: 1000;
    display: flex;
    align-items: center;
    height: 50px;
  }

  .navbar-brand img {
    height: 150px;
    width: auto;
    position: relative;
    transition: opacity 0.3s ease, height 0.3s ease;
  }

  /* Hamburger Menu Styling */
  .navbar-toggler {
    position: absolute;
    right: 10px;
    top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    z-index: 1002;
    background: none;
    border: none;
  }

  .navbar-toggler-icon {
    background-color: #333;
    height: 3px;
    width: 30px;
    position: relative;
    display: inline-block;
    transition: all 0.3s ease;
  }

  .navbar-toggler-icon:before,
  .navbar-toggler-icon:after {
    content: "";
    background-color: #333;
    height: 3px;
    width: 30px;
    position: absolute;
    left: 0;
    transition: all 0.3s ease;
  }

  .navbar-toggler-icon:before {
    top: -8px;
  }

  .navbar-toggler-icon:after {
    top: 8px;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    background-color: transparent;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: rotate(45deg);
    top: 0;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(-45deg);
    top: 0;
  }

  /* Full-screen mobile menu */
  .collapse.navbar-collapse {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    z-index: 1001;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .collapse.navbar-collapse.show {
    opacity: 1;
    visibility: visible;
  }

  /* Navbar menu items */
  .navbar-nav {
    flex-direction: column;
    text-align: center;
    gap: 20px;
    padding-top: 10px;
    width: 100%;
  }

  .navbar-nav .nav-item {
    padding: 10px 0;
  }

  .navbar-nav .nav-link {
    color: #fff !important;
    font-size: 24px;
    padding: 10px;
    transition: color 0.3s ease;
  }

  .navbar-nav .nav-link:hover {
    color: #1c544c !important;
  }

  /* CTA button positioning */
  .cta-button {
    position: absolute;
    right: 70px;
    top: 30px;
    width: auto;
    z-index: 1000;
  }

  .navbar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    display: none;
  }

  .navbar-toggler[aria-expanded="true"] ~ .navbar-overlay {
    display: block;
  }
}

@media (max-width: 767px) {
  header.custom-header {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0);
    transition: background 0.3s ease;
  }

  header.custom-header.custom-header-scrolled {
    background-color: rgba(37, 43, 45, 1);
  }

  .navbar-brand {
    display: flex;
    align-items: center;
  }

  .navbar-brand img {
    height: 160px;
    width: auto;
    margin-bottom: 0;
  }

  .navbar-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    z-index: 1002;
    background: none;
    border: none;
  }

  .navbar-toggler-icon {
    background-color: #333;
    height: 3px;
    width: 30px;
    position: relative;
    display: inline-block;
    transition: all 0.3s ease;
  }

  .navbar-toggler-icon:before,
  .navbar-toggler-icon:after {
    content: "";
    background-color: #333;
    height: 3px;
    width: 30px;
    position: absolute;
    left: 0;
    transition: all 0.3s ease;
  }

  .navbar-toggler-icon:before {
    top: -8px;
  }

  .navbar-toggler-icon:after {
    top: 8px;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    background-color: transparent;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: rotate(45deg);
    top: 0;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(-45deg);
    top: 0;
  }

  /* Full-screen mobile menu */
  .collapse.navbar-collapse {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    z-index: 1001;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .collapse.navbar-collapse.show {
    opacity: 1;
    visibility: visible;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }

  .navbar-nav .nav-item {
    padding: 10px 0;
  }

  .navbar-nav .nav-link {
    color: #fff !important;
    font-size: 24px;
    padding: 10px;
    transition: color 0.3s ease;
  }

  .navbar-nav .nav-link:hover {
    color: #1c544c !important;
  }

  /* CTA button positioning */
  .cta-button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    font-size: 14px;
    z-index: 1003;
  }

  .navbar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    display: none;
  }

  .navbar-toggler[aria-expanded="true"] ~ .navbar-overlay {
    display: block;
  }
}
