.perks-benefits-section {
  background-color: #1e1e1e;
  color: white;
  padding: 60px 0;
}

.perks-benefits-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.header {
  text-align: left;
  margin-bottom: 30px;
}

.header h2 {
  font-size: 36px;
  margin-bottom: 15px;
  color: white;
  font-weight: bold;
}

.perks-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.perk-card {
  background-color: #2b2b2b;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  transition: transform 0.3s;
  border: 1px solid #333;
}

.perk-card:hover {
  transform: translateY(-5px);
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  margin-bottom: 15px;
}

.icon-wrapper i {
  font-size: 30px;
  color: #21d07a;
}

.perk-card h4 {
  font-size: 20px;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
}

.perk-card p {
  font-size: 16px;
  color: #aaa;
  margin-bottom: 10px;
}

/* Responsive Design for Tablets */
@media (max-width: 1024px) {
  .perks-cards {
    grid-template-columns: 1fr 1fr;
  }

  .perk-card {
    padding: 15px;
  }

  .perk-card h4 {
    font-size: 18px;
  }

  .perk-card p {
    font-size: 14px;
  }
}

/* Responsive Design for Mobile Devices */
@media (max-width: 768px) {
  .perks-cards {
    grid-template-columns: 1fr;
  }

  .perk-card {
    padding: 12px;
  }

  .perk-card h4 {
    font-size: 16px;
  }

  .perk-card p {
    font-size: 13px;
  }

  .icon-wrapper i {
    font-size: 28px;
  }
}

/* Very Small Devices */
@media (max-width: 480px) {
  .perks-benefits-container {
    padding: 0 10px;
  }

  .perk-card {
    padding: 10px;
  }

  .perk-card h4 {
    font-size: 15px;
  }

  .perk-card p {
    font-size: 12px;
  }

  .icon-wrapper i {
    font-size: 25px;
  }
}
