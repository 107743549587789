/* Job Listing Section */
.job-listing-section {
  background-color: #1e1e1e;
  color: white;
  padding: 60px 0;
}

.job-listing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  text-align: left;
  margin-bottom: 30px;
}

.header h2 {
  font-size: 36px;
  margin-bottom: 15px;
  font-weight: bold;
  color: white;
}

.job-cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.job-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #2b2b2b;
  border-radius: 8px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #333;
}

.job-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.job-info h4 {
  font-size: 20px;
  color: white;
  margin-bottom: 5px;
  font-weight: bold;
}

.job-info p {
  font-size: 16px;
  color: #aaa;
}

.apply-button-wrapper {
  display: flex;
  align-items: center;
}

.apply-button {
  background-color: #21d07a;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apply-button:hover {
  background-color: #1ba965;
}

/* Tablet and smaller screens */
@media (max-width: 1024px) {
  .job-cards {
    gap: 15px;
  }

  .job-info h4 {
    font-size: 18px;
  }

  .job-info p {
    font-size: 14px;
  }

  .apply-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

/* Mobile and smaller screens */
@media (max-width: 768px) {
  .job-card {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .apply-button {
    align-self: flex-start;
  }
}

/* Very small screens (max-width: 480px) */
@media (max-width: 480px) {
  .job-listing-container {
    padding: 0 10px;
  }

  .job-info h4 {
    font-size: 16px;
  }

  .job-info p {
    font-size: 13px;
  }

  .apply-button {
    font-size: 14px;
    padding: 8px 14px;
  }
}
