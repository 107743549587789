.call-to-action-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #27ae60;
  padding: 60px 20px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  gap: 30px;
}

.text-container {
  flex: 1;
  color: white;
}

.button-container {
  flex-shrink: 0;
}

.call-to-action-section h2 {
  font-size: 4em;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
}

.text-container p {
  font-size: 2em;
  margin-top: 10px;
}

/* CTA Button */
.cta-button {
  display: inline-block;
  padding: 20px 40px;
  border: 2px solid white;
  color: white;
  background: none;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.8em;
}

.cta-button:hover {
  background-color: white;
  color: #27ae60;
}

/* Responsive Design for Tablets */
@media (max-width: 1024px) {
  .container {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }

  .call-to-action-section h2 {
    font-size: 3em;
  }

  .text-container p {
    font-size: 1.8em;
  }

  .cta-button {
    font-size: 1.6em;
    padding: 15px 30px;
  }
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .call-to-action-section {
    padding: 40px 20px;
  }

  .call-to-action-section h2 {
    font-size: 2.5em;
  }

  .text-container p {
    font-size: 1.6em;
  }

  .cta-button {
    font-size: 1.4em;
    padding: 12px 25px;
  }
}

/* Very small screens (max-width: 480px) */
@media (max-width: 480px) {
  .call-to-action-section {
    padding: 30px 20px;
  }

  .call-to-action-section h2 {
    font-size: 2em;
  }

  .text-container p {
    font-size: 1.4em;
  }

  .cta-button {
    font-size: 1.2em;
    padding: 10px 20px;
  }
}
