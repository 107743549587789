.our-values-section {
  background-color: #1e1e1e;
  color: white;
  padding: 60px 20px;
}

.our-values-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  text-align: left;
  margin-bottom: 40px;
}

.header h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.values-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.value-card {
  background-color: #2b2b2b;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
  transition: transform 0.3s, box-shadow 0.3s ease;
  border: 1px solid #333;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.value-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  margin-bottom: 20px;
}

.icon-wrapper i {
  font-size: 36px;
  color: #21d07a;
}

.value-card h4 {
  font-size: 22px;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
}

.value-card p {
  font-size: 16px;
  color: #aaa;
  margin-bottom: 10px;
  line-height: 1.5;
}

/* Responsive Design for Tablets */
@media (max-width: 1024px) {
  .values-cards {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .value-card h4 {
    font-size: 20px;
  }

  .value-card p {
    font-size: 15px;
  }
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .values-cards {
    grid-template-columns: 1fr;
  }

  .value-card {
    padding: 15px;
  }

  .value-card h4 {
    font-size: 18px;
  }

  .value-card p {
    font-size: 14px;
  }
}
