.careers-hero {
  background-color: #222;
  color: white;
  padding: 100px 20px;
  position: relative;
}

.careers-hero .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.careers-hero .title-section {
  text-align: center;
  margin-bottom: 40px;
  padding-top: 40px;
}

.careers-hero .title-section h1 {
  font-size: 4.5em;
  margin: 0;
  position: relative;
  z-index: 10;
}

.careers-hero .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0px;
}

.careers-hero .text-section {
  flex: 1;
  margin-right: 20px;
}

.careers-hero .headline h2.secondary-title {
  font-size: 3em;
  margin-bottom: 20px;
  font-weight: bold;
}

.careers-hero .headline p {
  font-size: 1.8em;
  max-width: 600px;
  margin-bottom: 20px;
  line-height: 1.6;
}

.careers-hero .image-section {
  flex: 1;
  max-width: 40%;
}

.careers-hero .image-section img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Responsive Design for Tablets */
@media (max-width: 1024px) {
  .careers-hero .content {
    flex-direction: column;
    align-items: center;
  }

  .careers-hero .text-section {
    margin-right: 0;
    text-align: center;
    max-width: 100%;
  }

  .careers-hero .image-section {
    max-width: 100%;
    margin-top: 20px;
  }

  .careers-hero .title-section h1 {
    font-size: 4em;
  }

  .careers-hero .headline h2.secondary-title {
    font-size: 2.5em;
  }

  .careers-hero .headline p {
    font-size: 1.6em;
  }
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .careers-hero {
    padding: 60px 20px;
  }

  .careers-hero .title-section h1 {
    font-size: 3.5em;
  }

  .careers-hero .headline h2.secondary-title {
    font-size: 2.2em;
  }

  .careers-hero .headline p {
    font-size: 1.4em;
  }

  .careers-hero .image-section img {
    max-width: 100%;
  }
}

/* Very small screens (max-width: 480px) */
@media (max-width: 480px) {
  .careers-hero {
    padding: 50px 20px;
  }

  .careers-hero .title-section h1 {
    font-size: 2.5em;
  }

  .careers-hero .headline h2.secondary-title {
    font-size: 2em;
  }

  .careers-hero .headline p {
    font-size: 1.2em;
  }
}
