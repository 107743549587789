.contact-banner-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #27ae60;
  padding: 60px 0;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
}

.text-container {
  flex: 1;
}

.button-container {
  flex-shrink: 0;
}

.contact-banner-section h2 {
  color: white;
  font-size: 3em;
  font-weight: bold;
  margin: 0;
}

.contact-button {
  display: inline-block;
  padding: 12px 30px;
  border: 2px solid white;
  color: white;
  background: none;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.6em;
}

.contact-button:hover {
  background-color: white;
  color: #27ae60;
}

/* Responsive Design for Tablets */
@media (max-width: 1024px) {
  .container {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }

  .text-container h2 {
    font-size: 2.5em;
  }

  .button-container {
    flex-shrink: initial;
  }

  .contact-button {
    font-size: 1.5em;
    padding: 12px 25px;
  }
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .contact-banner-section {
    padding: 50px 0;
  }

  .container {
    padding: 0 15px;
  }

  .text-container h2 {
    font-size: 2em;
  }

  .contact-button {
    font-size: 1.3em;
    padding: 10px 20px;
  }
}

/* Very small screens (max-width: 480px) */
@media (max-width: 480px) {
  .contact-banner-section {
    padding: 40px 0;
  }

  .text-container h2 {
    font-size: 1.8em;
  }

  .contact-button {
    font-size: 1.2em;
    padding: 10px 18px;
  }
}
