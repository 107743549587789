.summary-footer {
  background-color: #1e1e1e;
  color: white;
  padding: 80px 20px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.footer-column {
  flex: 1;
  min-width: 280px;
  margin: 10px;
  text-align: left;
}

.footer-column h3,
.footer-column h4 {
  font-size: 2em; /* Adjusted to match other sections */
  margin-bottom: 20px;
  position: relative;
}

.footer-column hr {
  border: 0;
  border-top: 1px solid #444;
  margin: 10px 0 20px;
}

.footer-column p {
  font-size: 1.5em; /* Adjusted for consistency */
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.footer-column p i {
  margin-right: 8px;
}

.footer-column.contact-info .location {
  margin-bottom: 40px;
}

.social-icons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.social-icons i {
  background: #333;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.social-icons i:hover {
  background-color: #27ae60;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-form input,
.contact-form textarea {
  padding: 12px;
  border: 1px solid #333;
  border-radius: 5px;
  background: #1e1e1e;
  color: white;
  font-size: 1.2em; /* Consistent font size */
}

.contact-form textarea {
  resize: none;
  height: 120px;
}

.contact-form button {
  padding: 12px;
  background: #27ae60;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 1.2em;
}

.contact-form button:hover {
  background: #1e8e50;
}

/* Responsive Design for Tablets */
@media (max-width: 1024px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-column {
    margin: 20px 0;
  }

  .footer-column h3,
  .footer-column h4 {
    font-size: 1.8em;
  }

  .footer-column p {
    font-size: 1.4em;
  }
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .summary-footer {
    padding: 60px 20px;
  }

  .footer-column h3,
  .footer-column h4 {
    font-size: 1.6em;
  }

  .footer-column p {
    font-size: 1.2em;
  }

  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    font-size: 1.1em;
  }
}

/* Very small screens (max-width: 480px) */
@media (max-width: 480px) {
  .summary-footer {
    padding: 50px 20px;
  }

  .footer-column h3,
  .footer-column h4 {
    font-size: 1.4em;
  }

  .footer-column p {
    font-size: 1.1em;
  }

  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    font-size: 1em;
  }
}
